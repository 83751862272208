<template>
  <div>
    <el-button
      type="primary"
      class="btnAdd"
      @click="dialogVisible = true"
      v-if="show"
      >文章采集</el-button
    >

    <el-card style="margin-top: 10px">
      <el-form
        ref="formref"
        :model="form"
        :rules="addFormRulue"
        label-width="100px"
      >
        <el-form-item label="资讯标题" prop="title">
          <el-col :span="8">
            <el-input
              v-model="form.title"
              :maxlength="150"
              show-word-limit
              @blur="changs"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="来源">
          <el-col :span="8">
            <el-input v-model="form.source" />
          </el-col>
        </el-form-item>
        <el-form-item label="作者">
          <el-col :span="8">
            <el-input v-model="form.author" />
          </el-col>
        </el-form-item>
        <el-form-item label="原文链接">
          <el-col :span="8">
            <el-input v-model="form.originLink">
              <!-- <template slot="prepend">http://</template> -->
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="资讯分类" prop="acId">
          <el-select v-model="form.acId" placeholder="请选择">
            <el-option
              v-for="item in fen"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="发布类型" prop="type">
          <el-col :span="10">
            <el-select v-model="form.type">
              <el-option label="转载" :value="1" />
              <el-option label="伪原创" :value="2" />
              <el-option label="原创" :value="3" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="提交收录" prop="record">
          <el-select v-model="form.record">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </el-form-item>

        <el-form-item label="专题">
          <el-select
            v-model="form.tagId"
            multiple
            placeholder="请选择"
            :multiple-limit="3"
          >
            <el-option
              v-for="item in zhi"
              :key="item.id"
              :label="item.name"
              :value="item.id + ''"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="置顶">
          <el-select v-model="form.top" placeholder="请选择">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="picture">
          <!-- <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="form.picture"
              :src="baseurl + yu + form.picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div class="el-upload__tip waring" slot="tip">
              请上传比例16:9的图片，否则会影响前台显示 <br />
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload> -->
          <up-img :action="action" :banner="form.picture" @event="setImg">
          </up-img>
        </el-form-item>
        <el-form-item label="封面(大图)">
          <up-img
            :action="action"
            @event="set_bigPicture"
            :banner="form.bigPicture"
          ></up-img>
        </el-form-item>
        <el-form-item label="摘要" prop="desc">
          <el-input
            v-model="form.desc"
            type="textarea"
            placeholder="请输入内容"
            :maxlength="300"
            show-word-limit
            @input="xuecha"
          />
        </el-form-item>
        <el-form-item label="资讯内容" prop="content">
          <!-- <quill-editor v-model="form.content" :options="quillOption" /> -->
          <Tiny :value="form.content" @input="chang"></Tiny>
        </el-form-item>

        <el-form-item label="SEO标题" prop="seoTitle">
          <el-input
            v-model="form.seoTitle"
            placeholder="<title>标签内容</title>"
            :maxlength="300"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="SEO关键词">
          <el-input
            v-model="form.seoKeyword"
            placeholder='<meta name="keywords" content="标签内容">'
          />
        </el-form-item>
        <el-form-item label="SEO描述" prop="seoDesc">
          <el-input
            v-model="form.seoDesc"
            placeholder='<meta name="description" content="标签内容">'
            @input="cha"
          />
        </el-form-item>
        <!-- <el-form-item label="初始阅读量"> -->
        <!-- <el-input-number
            v-model="form.initViews"
            placeholder="请输入内容"
            :min="0"
          /> -->
        <!-- <el-tooltip class="item" effect="dark" content="初始阅读量默认值∶50-200随机。 每篇文章虚拟阅读量发布48小时内,每小时随机增加10-50阅读量。" placement="top">
            <i class="el-icon-question"></i>
          </el-tooltip> -->
        <!-- </el-form-item> -->
        <!-- <el-form-item label="叠加档次" prop="viewsRule">
          <el-select v-model="form.viewsRule" placeholder="请选择">
            <el-option value="10,100" label="10-100" />
            <el-option value="100,200" label="100-200" />
            <el-option value="200,300" label="200-300" />
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-button v-if="id == null" type="primary" @click="onSubmit">{{
            btn1 ? '立即提交' : 'loading..'
          }}</el-button>
          <el-button v-if="id" type="primary" @click="addedit">{{
            btn1 ? '修改提交' : 'loading..'
          }}</el-button>
          <!-- <el-button>取消</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog title="文章采集" :visible.sync="dialogVisible" width="50%">
      <el-form ref="editFormRef" label-width="70px" :model="editform">
        <el-form-item label="采集源" prop="realname">
          <el-radio-group v-model="editform.source">
            <!-- 单选按钮A -->
            <el-radio label="36氪">36氪</el-radio>
            <el-radio label="创业邦">创业邦</el-radio>
            <el-radio label="雨果网">雨果网</el-radio>
            <el-radio label="腾讯科技">腾讯科技</el-radio>
            <el-radio label="钛媒体">钛媒体</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="URL" prop="username">
          <el-input v-model="editform.url" placeholder="请输入url链接">
            <!-- <template slot="prepend">http://</template> -->
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sub">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import quillConfig from '../../quill'
// import Tiny from '../../components/Tinymce'
import upImg from '../../components/upImg'
import $ from 'jquery'
export default {
  data() {
    return {
      action: {
        action: 'article'
      },
      show: true,
      quillOption: quillConfig,
      addFormRulue: {
        title: [{ required: true, message: '请输入资讯标题', trigger: 'blur' }],
        acId: [{ required: true, message: '请输入资讯分类', trigger: 'blur' }],
        type: [{ required: true, message: '请输入发布类型', trigger: 'blur' }],
        record: [
          { required: true, message: '请输入提交收录', trigger: 'blur' }
        ],
        picture: [{ required: true, message: '请上传封面', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入资讯内容', trigger: 'blur' }
        ],
        seoTitle: [
          { required: true, message: '请输入seo标题', trigger: 'blur' }
        ],
        seoDesc: [{ required: true, message: '请输入seo描述', trigger: 'blur' }]
      },
      form: {
        acId: '',
        title: '',
        type: '',
        picture: '',
        record: 1,
        resource: '',
        content: '',
        desc: '',
        seoTitle: '',
        seoKeyword: '',
        seoDesc: '',
        publishStatus: '',
        source: '',
        initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
        tagId: '',
        author: '',
        originLink: '',
        top: '',
        bigPicture: ''
        // viewsRule: '10,100'
      },
      tagId: '',
      btn: true,
      id: null,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      zhi: [],
      fen: [],
      editform: {
        source: '',
        url: ''
      },
      header: {
        Token: sessionStorage.getItem('token')
      },
      dialogVisible: false,
      btn1: true
    }
  },
  updated() {
    console.log(this.form)
    // this.form.desc = this.form.seoDesc
    // this.form.seoDesc = this.form.desc
  },
  created() {
    this.getzhi()
    this.getfen()
    this.getlist()
    sessionStorage.setItem('action', 'articleEditor')
    this.bus.$on('new', () => {
      this.form = {
        acId: '',
        title: '',
        type: '',
        picture: '',
        record: 1,
        resource: '',
        content: '',
        desc: '',
        seoTitle: '',
        seoKeyword: '',
        seoDesc: '',
        publishStatus: '',
        source: '',
        initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
        tagId: '',
        author: '',
        originLink: '',
        viewsRule: '10,100'
      }
    })
  },
  mounted() {
    // 监听页面滚动事件
    $(document).scroll(function() {
      if ($('.quill-editor').offset()) {
        if ($(document).scrollTop() > $('.quill-editor').offset().top) {
          $('.ql-toolbar').css({
            'background-color': 'white',
            position: 'fixed',
            top: '0',
            left: '0',
            'z-index': '999',
            width: '100%'
          })
        } else {
          $('.ql-toolbar').css({
            'background-color': 'white',
            position: 'static',
            top: '0',
            left: '0',
            width: '100%'
          })
        }
      }
    })
    // 注意：如果由于自己的vue中的样式导致监听不到，可以尝试监听body或者'#app-root'的滚动事件
  },
  methods: {
    set_bigPicture(val) {
      this.form.bigPicture = val
    },
    chang(val) {
      this.form.content = val
    },
    setImg(val) {
      this.form.picture = val
    },
    cha() {
      this.form.desc = this.form.seoDesc
    },
    xuecha() {
      this.form.seoDesc = this.form.desc
    },
    changs() {
      // console.log(1, !!this.form.seoTitle);
      if (!!!this.form.seoTitle) {
        this.form.seoTitle = this.form.title
        console.log(1)
      }
    },
    onEditorFocus() {
      // console.log($('.ql-toolbar'));
      $('.ql-toolbar').css({
        'background-color': 'white',
        position: 'fixed',
        top: '0',
        left: '0',
        'z-index': '999',
        width: '100%'
      })
    },
    onEditorBlur() {
      $('.ql-toolbar').css({
        'background-color': 'white',
        position: 'static',
        top: '0',
        left: '0',
        width: '100%'
      })
    },
    onSubmit() {
      // console.log(this.form)
      const form = {
        ...this.form,
        tagId: !!this.form.tagId && this.form.tagId.join(','),
        originLink: !!this.form.originLink
          ? 'http://' + this.form.originLink
          : this.form.originLink
      }
      this.$refs.formref.validate(() => {
        if (this.btn1) {
          this.$http
            .post('/admin/Article/add', form)
            .then(({ data: res }) => {
              if (+res.errorCode === 200) {
                this.$message.success(res.message)
                this.$router.push('/article_list')
              } else {
                this.$message.error(res.message)
              }
              this.btn1 = true
            })
            .catch(() => {
              this.$message.error('上传失败')
            })
        }
        this.btn1 = false
      })
    },
    getzhi() {
      this.$http.get('/admin/Tag/getList?type=1').then(({ data: res }) => {
        // console.log(res);
        if (res.errorCode == 200) {
          this.zhi = res.data.list
          // this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
          
        }
      })
    },
    getfen() {
      this.$http.get('/admin/ArticleCategory/getList').then(({ data: res }) => {
        this.fen = res.data.list
      })
    },
    getlist() {
      // console.log(this.$route.query);
      this.id = this.$route.query.id
      if (this.$route.query.id) {
        this.show = false
        this.$http
          .get('/admin/Article/getById?id=' + this.id)
          .then(({ data: res }) => {
            console.log(res)
            if (res.errorCode == 200) {
              // this.$message.success(res.message)
              this.form = res.data
              // if (this.form.top == 0) {
              //   this.form.top = ''
              // }
              this.form.tagId = !!this.form.tagId && this.form.tagId.split(',')
              console.log(this.form)
            } else {
              // console.log(res);
              if (res.errorCode == 201003) {
                sessionStorage.removeItem('token')
                this.$router.push('/login')
              }
              this.$message.error(res.message)
            }
          })
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.picture = res.data.url
        console.log(file)
      } else {
        this.$message.error(res.message)
      }
      // this.form.banner = URL.createObjectURL(file.raw)
    },
    addedit() {
      const form = {
        ...this.form,
        tagId: !!this.form.tagId && this.form.tagId.join(',')
      }
      this.$refs.formref.validate(() => {
        if (this.btn1) {
          this.$http.post('/admin/Article/edit', form).then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/article_list')
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                customClass: 'zZindex'
              })
            }
            this.btn1 = true
          })
        }
        this.btn1 = false
      })
    },

    sub() {
      if (this.btn) {
        this.$http
          .get('/admin/Spider/getArticle', {
            params: { url: this.editform.url }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.form = res.data
              this.form.source = this.editform.source
              this.form.type = 1
              this.form.title = res.data.title[0]
              this.form.seoTitle = res.data.title
              this.form.record = 1
              this.form.originLink = this.editform.url
              // this.form.content = res.data.content
              this.form.seoDesc = res.data.desc
              // this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
              this.dialogVisible = false
            } else {
              this.$message.error(res.message)
              
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  },
  computed: {
    lasturl() {
      if (this.form.isOld) {
        return '/yfchuhai.com/dedecms'
      } else {
        return ''
      }
    }
  },
  components: {
    // Tiny,
    upImg
  }
}
</script>

<style lang="less" scoped>
.item {
  margin-left: 5px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: auto;
  height: 178px;
  display: block;
}
.el-input--medium .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
<style>
.el-message.el-message--error {
  z-index: 20000 !important;
}
</style>
